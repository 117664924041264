<template>
 <div class="bigDiv" >
<div class="bigDiv"  id="img">
  <div class="topImg">
  <div class="fenshu">
    <div>较高风险</div>
    <div class="xiao">综合分数53</div>
  </div>
  <van-circle v-model="currentRate" :rate="rate" :speed="100" :text="`综合:`+text" layer-color='rgba(118,222,181,.5)' size="140px"   :stroke-width="120" :color="gradientColor">

  </van-circle>

</div>
<div class="topText">您的身体状况正在下降，功能失衡明显，存在一些常见疾病的危险因素，或已经处在疾病的发展期。如果不及时调整自己的饮食方式和生活习惯，某些潜在的威胁与疾病会有机可乘，所以您需要马上提高健康意识，开始进行调理干预，以避免疾病的发生和发展。</div>
<div class="bodyText" v-for="item in 4 " :key="item">
  <div class="textTitle">肝胆功能风险:高</div>
  <div class="textdetails">您的肝胆系统负担较重，存在一定危机，可能已造成一些损害或显现出某些疾病。应予以高度重视，尽快进行调理干预，以避免发生疾病或危机加重</div>
</div>
</div>
<div class="btnfixd" @click="getImage">
  <img src="../../assets/images/shiliao/btn.gif" alt="">
</div>
<el-dialog
  :visible.sync="imgurls"
  >
  <div class="imgs">
    <img :src="imgUrl" alt="">
  </div>
  <div class="tips">长按图片保存/发给指导师</div>

</el-dialog>
 </div>
</template>

<script>
import { toPng } from 'html-to-image'

 export default {
   data () {
   return {
    rate:'68',
     currentRate: 0,
     imgurls:false,
     imgUrl:'',
      gradientColor: {
        '50%': '#28b881',
        // '25%':'#88e7db',
        '70%': '#eb5f24',
        '100%': 'red',
      
      },
   }
  },
  methods: {
 getImage() {
      const node = document.querySelector('#img')
      toPng(node).then((dataUrl) => {
        // 使用图片数据URL
        this.imgUrl = dataUrl
        this.dialogTableVisible=true
        this.imgurls=true
      })
        .catch(function (error) {
          console.error('转换错误:', error);
        });
    },
  },
  mounted() {
if (this.rate>80) {
  this.gradientColor={
     '0%': '#eb5f24',
     '100%': 'red',
  }
  
} 
  },
  watch: {

  },
  computed: {
  text() {
      return this.currentRate.toFixed(0) + '分';
    },
  },
  filters: {

  }
 }
</script>

<style scoped lang='less'>
.bigDiv{
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
    background-image: url(../../assets/images/shiliao/ceping.jpg);
    background-size: cover;
    background-repeat: no-repeat;

  .topImg{
    display: flex;
    text-align: center;
    width: 340px;
    margin: 0 auto;
    margin-top: 120px;
    background-color: rgba(255,255,255,.5);
    border-radius: 20px;
    padding: 10px;
    .fenshu{
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  margin-left: 20px;
  margin-right: 50px;
  width: 120px;
  margin-top: 30px;
  .xiao{
    font-size: 18px;
    font-weight: 200;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #00a37c;
    background-color: #e0fff2;
    margin-top: 20px;
  }
    }
  }
  .topText{
    padding: 15px;
    width: 340px;
    margin: 0 auto;
    background-color: #f5fbf9;
    line-height: 2.5rem;
    font-size: 18px;
    border-radius: 20px;
  }
  .bodyText{
    width: 340px;
    background-color: #f5fbf9;
    margin: 10px auto;
    padding: 10px;
    line-height: 2rem;
    font-size: 18px;
    border-radius: 20px;
    .textTitle{
      color: #ea5f28;
      font-weight: 700;
      font-size: 17px;
    }
  }
  .btnfixd{
    margin-top: 80px;
    img{
      width: 100%;
      position: fixed;
      bottom: 0;
    }
  }
}
@media screen and (min-width: 750px) {
  .bigDiv{
    width: 750px;
    margin: 0 auto;
      overflow: hidden;
    background-image: url(../../assets/images/shiliao/ceping.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    
  .topImg{
    display: flex;
    text-align: center;
    justify-content: space-around;
    width: 540px;
    background-color: #f5fbf9;
    border-radius: 20px;
    padding: 10px;
    margin: 0 auto;
    margin-top: 30%;

    .fenshu{
  font-size: 28px;
  font-weight: 700;
  margin-left: 20px;
  margin-right: 60px;
  width: 120px;
  margin-top: 15px;
  .xiao{
    font-size: 18px;
    font-weight: 200;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #00a37c;
    background-color: #e0fff2;
    margin-top: 20px;
  }
    }
  }
    .topText{
    padding: 15px;
    width: 540px;
    margin: 0 auto;
    background-color: #f5fbf9;
    line-height: 2.5rem;
    font-size: 18px;
    border-radius: 20px;
    margin: 20px auto;
  }
  .bodyText{
    width: 540px;
    background-color: #f5fbf9;
    margin: 10px auto;
    padding: 10px;
    line-height: 2rem;
    font-size: 18px;
    border-radius: 20px;
    .textTitle{
      color: #ea5f28;
      font-weight: 700;
      font-size: 17px;
    }
  }
    .btnfixd{
    margin-top: 80px;
    width: 750px;
    margin: 0 auto;
    margin-bottom: 130px;
    img{
      width: 600px;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
  }
  }
} 
  

</style>
<style  lang='less'>

@media screen and (max-width:750px) {
    .el-dialog{
    width: 80%;
height: 500px;
border-radius: 20px;
 overflow: auto;
position: relative;
top: -20px;
.imgs{
  width: 100%;

  img{
    width: 100%;
  }
}
.tips{
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(0, 0, 0, 0);
  color: #f9f9f9;
  font-size: 20px;
width: 100%;
text-align: center;
}
.el-dialog__wrapper{
  background-color: rgba(0, 0, 0, .5);
  top: -100px;
}
}
}
@media screen   and (min-width:750px){
    .el-dialog{
  width: 600px;
height: 650px;
border-radius: 20px;
 overflow: auto;
position: relative;
top: -50px;
.imgs{
  width: 100%;

  img{
    width: 100%;
    object-fit: contain;
  }
}
.tips{
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(0, 0, 0, 0);
  color: #f9f9f9;
  font-size: 20px;


}
}
.el-dialog__wrapper{
  background-color: rgba(0, 0, 0, .5);
  top: 0;

}
.el-dialog__header{
  padding: 0;
}
.el-dialog__close{
  margin-top: -20px;
}
}
</style>